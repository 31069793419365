body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.image-gallery {
  width: 100%;
  height: auto;
}

.image-gallery-slide img {
  width: 100%;
  height: 100%;
  max-height: 85vh;
  object-fit: cover;
  overflow: hidden;
  object-position: center center;
}

.fullscreen .image-gallery-slide img {
  max-height: 100vh;
}

.header_text {
  font-size: 28px;
  font-weight: bold;
  text-transform: uppercase;
  font-family: "futura-pt-condensed";
  margin-bottom: 20px;
}

.listing_title
{
  font-size: 30px;
  font-weight: 600;
  color: #52635d;
  padding-bottom: 10px
}
.listing_text
{
  margin-top: 8px;
  margin-bottom: 0px;
}
.room_tags
{
  margin-top: 9px;
}
.room_tag
{
  display: inline-block;
  font-size: 12px;
  background: #82b6a4;
  margin-right: 7px;
}
.room_tag a
{
  display: block;
  color: #FFFFFF;
  padding-left: 13px;
  padding-right: 13px;
  padding-top: 1px;
  padding-bottom: 1px;
}
.featured_card_box
{

  width: 75%;
  height: 66px;
  background: #1e2c4e;
  padding: 20px;

}


.featured_card_price
{
  font-size: 80%;
  font-weight: 600;
  color: #FFFFFF;
}

.rooms
{
  margin-top: -132px;
}
.room
{
  display: inline-block;
  margin-right: 17px;
  margin-bottom: 6px;
  margin-top: -50px;
}
.room_title
{
  font-size: 12px;
  font-weight: 400;
  color: #2e2e2e;
}
.room_content
{
  margin-top: 8px;
  padding-left: 2px;
}
.room_image
{
  display: inline-block;
  width: 32px;
}
.room_number
{
  position: relative;
  top: -5px;
  font-size: 12px;
  font-weight: 400;
  color: #2e2e2e;
  margin-left: 5px;
}

